import React from 'react'

import {Box, Container, Grid} from '@mui/material'
import Popover from '@mui/material/Popover'

import crosshair from '../assets/crosshair.png'
import char1 from '../assets/char-1.png'
import char2 from '../assets/char-2.png'
import char3 from '../assets/char-3.png'
import char4 from '../assets/char-4.png'

const Target = ({className, img}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Box
        sx={{
          transition: 'all 300ms',
          '&:hover': {
            transform: 'scale(1.3) translate(0, 0)',
          },
        }}
      >
        <Box
          component="img"
          className={className}
          sx={{
            height: 60,
            cursor: 'pointer',
          }}
          alt="Item"
          src={crosshair}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box
          sx={{
            p: 1,
            pb: 0,
            background: '#000',
          }}
        >
          <Box component="img" alt="Item" src={img} sx={{height: 200}} />
        </Box>
      </Popover>
      <Box sx={{display: 'none'}}>
        <Box component="img" alt="Item" src={img} />
      </Box>
    </>
  )
}

export default function Landing() {
  return (
    <Container
      maxWidth={false}
      className="bg-1"
      sx={{height: '100vh', py: '10vh'}}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
          >
            <Target className="float-y" img={char1} />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} sm={3} />
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
          >
            <Target className="float-x" img={char2} />
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
          >
            <Target className="float-y" img={char3} />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} />
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
          >
            <Target className="float-x" img={char4} />
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}

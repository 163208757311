import {createTheme} from '@mui/material/styles'

// const colors = {
//   orange: '#fcc328',
//   purple: '#38174d',
//   blue: '#12273d',
//   rust: '#58291b',
// }

const theme = createTheme({
  // palette: {
  //   type: 'light',
  //   primary: {
  //     main: colors.primary,
  //     light: '#f1cfbf',
  //     dark: '#452e33',
  //   },
  //   secondary: {
  //     contrastText: '#fff',
  //     main: colors.secondary,
  //     light: '#8ddfd4',
  //     dark: '#69a8a0',
  //   },
  //   tertiary: {
  //     main: colors.tertiary,
  //     light: '#c2e0f0',
  //     dark: '#387da0',
  //   },
  // },
  typography: {
    // fontFamily: ['Roboto Flex', 'sans-serif'].join(','),
    // p: {
    //   fontSize: '1.25rem',
    //   margin: '0 0 1em',
    // },
    // h1: {
    //   fontFamily: ['Inter', 'sans-serif'].join(','),
    //   fontWeight: '900',
    //   textTransform: 'uppercase',
    //   color: '#fff',
    // },
    // h2: {
    //   fontFamily: ['Inter', 'sans-serif'].join(','),
    //   fontWeight: '900',
    //   textTransform: 'uppercase',
    // },
    // h3: {
    //   // fontFamily: ['Rubik', 'sans-serif'].join(','),
    //   fontFamily: ['Inter', 'sans-serif'].join(','),
    //   fontWeight: '900',
    //   fontSize: '2.125rem',
    //   textTransform: 'uppercase',
    //   //   color: '#333',
    //   margin: '0 0 1em',
    // },
    // h4: {
    //   // fontFamily: ['Rubik', 'sans-serif'].join(','),
    //   fontFamily: ['Inter', 'sans-serif'].join(','),
    //   fontWeight: '900',
    //   fontSize: '1.5rem',
    //   // textTransform: 'uppercase',
    //   color: '#333',
    //   margin: '0 0 1em',
    // },
    // h5: {
    //   // fontFamily: ['Rubik', 'sans-serif'].join(','),
    //   fontFamily: ['Inter', 'sans-serif'].join(','),
    //   fontWeight: '900',
    //   fontSize: '1.25rem',
    //   // textTransform: 'uppercase',
    //   color: '#333',
    //   margin: '0 0 1em',
    // },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          // borderRadius: 20,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 20,
        },
      },
    },
  },
})

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: 320, md: 640, lg: 800},
  height: {xs: 180, md: 360, lg: 450},
  boxShadow: 24,
}

export default theme
